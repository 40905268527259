<template>
  <div>
    <el-table
      :header-cell-style="{ textAlign: 'center' }"
      :cell-style="{ textAlign: 'center' }"
      :data="data"
      border
      @selection-change="handleSelectionChange"
    >
      <el-table-column v-if="showSelection" type="selection" width="55">
      </el-table-column>
      <el-table-column
        v-if="showIndex"
        type="index"
        label="序号"
        width="80"
      ></el-table-column>
      <template v-for="(item, index) in columns">
        <el-table-column
          v-if="
            !item.slotName &&
            item.prop != 'index' &&
            !item.formatter &&
            !item.hidden
          "
          :label="item.label"
          :key="index"
          :prop="item.prop"
          :width="item.width ? item.width : '200'"
          :fixed="item.fixed"
        >
          <div slot-scope="scope">
            <el-tooltip placement="top" v-if="item.showTooltip">
              <div slot="content" style="max-width: 500px">
                {{ scope.row[item.prop] }}
              </div>
              <div class="table-cell-overflow">
                {{ scope.row[item.prop] }}
              </div>
            </el-tooltip>
            <div v-if="!item.showTooltip">
              {{ scope.row[item.prop] }}
            </div>
          </div>
        </el-table-column>
        <el-table-column
          v-if="item.formatter && !item.hidden"
          :label="item.label"
          :key="index"
          :prop="item.prop"
          :formatter="item.formatter"
          :width="item.width ? item.width : '200'"
          :fixed="item.fixed"
        >
        </el-table-column>
        <slot :name="item.slotName" v-if="item.slotName"></slot>
      </template>
      <slot name="operate"></slot>
    </el-table>
    <div style="text-align: center; margin: 20px 0 0" v-if="pageSize">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        layout="prev, pager, next"
        :page-size="pageSize"
        :current-page="pageNum"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  props: [
    'columns',
    'data',
    'pageSize',
    'pageNum',
    'total',
    'showIndex', // 是否显示序号
    'showSelection' // 是否显示复选框按钮
  ],
  data() {
    return {}
  },
  methods: {
    handleCurrentChange(pageNum) {
      this.$emit('get-list', pageNum)
    },
    // 当选择项发生变化时
    handleSelectionChange(selectedRowList) {
      this.$emit('selection-change', selectedRowList)
    }
  }
}
</script>
<style lang="scss" scoped>
.table-cell-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
::v-deep .el-table td,
::v-deep .el-table th {
  padding: 9px 0;
}
::v-deep .el-button--mini,
.el-button--mini.is-round {
  height: 28px;
}
</style>