<template>
  <div class="position-info">
    <div class="left">
      <el-tree
        node-key="id"
        :expand-on-click-node="false"
        :default-expanded-keys="keys"
        :data="DeptTree"
        :props="DeptTreeProps"
        @node-click="DeptTreeNodeClick"
      ></el-tree>
    </div>
    <div class="right">
      <div class="head">
        <el-form class="info-form" auto-complete="on">
          <el-form-item label="岗位名称：">
            <el-input
              v-model="requestParams.postName"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>

          <el-button
            @click="tableSearch"
            style="margin: 20px 10px 0 20px; float: left"
            type="primary"
            >搜索</el-button
          >
          <el-button
            @click="tableClear"
            style="margin: 20px 10px; float: left"
            type="primary"
            >清空</el-button
          >
          <el-button
            @click="
              Infotitle = '新增岗位'
              operationorg = true
              InfotableClear()
            "
            style="margin: 20px 10px; float: left"
            v-if="hasPermission.includes('新增')"
            type="primary"
            >增加
          </el-button>
        </el-form>
      </div>
      <div class="content">
        <el-table
          :header-cell-style="{ textAlign: 'center' }"
          :cell-style="{ textAlign: 'center' }"
          :data="tableData"
          max-height="630"
          v-loading="listLoading"
          border
          style="width: 97%; margin: 0 30px"
        >
          <el-table-column prop="id" label="ID" v-if="false" width="60">
          </el-table-column>

          <el-table-column prop="number" label="序号" width="50">
          </el-table-column>

          <el-table-column
            :show-overflow-tooltip="true"
            prop="postName"
            label="岗位名称"
          >
          </el-table-column>

          <el-table-column
            :show-overflow-tooltip="true"
            prop="deptName"
            label="所在部门"
          >
          </el-table-column>

          <el-table-column
            :show-overflow-tooltip="true"
            prop="parentName"
            label="上级岗位"
          >
          </el-table-column>

          <el-table-column label="操作" width="300">
            <template slot-scope="scope">
              <el-button
                size="mini"
                v-if="hasPermission.includes('设为主管')"
                @click="
                  manageFlag = true
                  manageflag(scope.row)
                "
                :disabled="scope.row.manageFlag === 1"
              >
                {{ scope.row.manageFlag === 1 ? '主管' : '设为主管' }}
              </el-button>

              <el-button
                size="mini"
                v-if="hasPermission.includes('编辑')"
                @click="
                  operationorg = true
                  editOrg(scope.row)
                  Infotitle = '编辑岗位'
                "
                >编辑
              </el-button>

              <el-button
                size="mini"
                v-if="hasPermission.includes('删除')"
                @click="handleDlelte(scope.$index, scope.row)"
                >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="text-align: center; margin: 10px">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            layout="prev, pager, next"
            :page-size="requestParams.pageSize"
            :current-page="requestParams.pageNum"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!--    弹出框-->
    <el-dialog title="删除岗位" :visible.sync="removeorg" width="25%">
      <div>确定要删除该岗位吗？</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="removeorg = false">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="removeOrg()"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog title="设为主管" :visible.sync="manageFlag" width="25%">
      <div>
        当前部门主管 <span style="color: red">{{ currentManage }}</span>
      </div>
      <div>
        是否将 <span style="color: red">{{ newtManage }}</span> 设置为部门主管
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="manageFlag = false">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="editmanageFlag()"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      :title="Infotitle"
      :visible.sync="operationorg"
      @close="$refs.addForm.clearValidate()"
      width="25%"
    >
      <div>
        <el-form
          :model="addForm"
          :rules="addFormrules"
          ref="addForm"
          class="addForm"
          style="
            overflow: hidden;
            padding-left: 30px;
            display: flex;
            flex-wrap: wrap;
          "
        >
          <el-form-item label="岗位名称" prop="postName">
            <el-input
              v-model="addForm.postName"
              placeholder="请输入"
            ></el-input>
          </el-form-item>

          <el-form-item label="所在部门" prop="deptId">
            <el-cascader
              @change="MenucascaderSelectChange"
              ref="cascaderHandle"
              filterable
              :props="{
                checkStrictly: true,
                children: 'childList',
                label: 'orgName',
                value: 'id'
              }"
              :options="DeptTree"
              v-model="addForm.deptId"
              :show-all-levels="false"
            ></el-cascader>
          </el-form-item>

          <el-form-item label="上级岗位" prop="parentId">
            <el-select
              v-model="addForm.parentId"
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in PostList"
                :key="item.id"
                :label="item.postName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            class="manageFlag"
            label="是否部门主管"
            prop="manageFlag"
          >
            <el-radio-group v-model="addForm.manageFlag">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="operationorg = false">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="doaddOrg()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  editDirector,
  editPost,
  findPostList,
  getDeptTree,
  insertPost,
  queryDirector,
  removePost
} from '@/api/organization'
import { findRoleMenuButton } from '@/api/user'

export default {
  name: 'PositionInformation',
  data() {
    return {
      hasPermission: [],
      Infotitle: '',
      keys: ['1'],
      loading: false,
      DeptTree: [],
      DeptTreeProps: {
        children: 'childList',
        label: 'orgName'
      },
      orglist: [],
      listLoading: false,
      //表格
      tableData: [],
      requestParams: {
        pageNum: 1,
        pageSize: 10,
        deptId: '',
        deptName: ''
      },
      total: 0,
      //按钮
      operationorg: false,

      //岗位信息新增
      addForm: {
        parentId: '',
        postName: '',
        deptId: '',
        manageFlag: ''
      },
      addFormrules: {
        postName: [
          {
            required: true,
            message: '请输入岗位名称',
            trigger: ['blur', 'change']
          },
          {
            min: 2,
            max: 32,
            message: '请输入2-32个字符',
            trigger: ['blur', 'change']
          }
        ],
        deptId: [
          {
            required: true,
            message: '请输入所在部门',
            trigger: ['blur', 'change']
          }
        ],
        parentId: [
          {
            required: true,
            message: '请输入上级岗位',
            trigger: ['blur', 'change']
          }
        ],
        manageFlag: [
          {
            required: true,
            message: '请选择是否部门主管',
            trigger: ['blur', 'change']
          }
        ]
      },
      // 删除岗位
      removeorg: false,

      //主管弹出层
      manageFlag: false,

      //当前主管
      currentManage: '',
      //新主管
      newtManage: '',
      PostList: ''
    }
  },
  created() {
    this.getDeptTree()
    this.doGetList()
    findRoleMenuButton({ name: 'PositionInformation' }).then((res) => {
      this.hasPermission = res.data
    })
  },
  methods: {
    FindPostList() {
      findPostList({
        pageNum: 1,
        pageSize: 99999
      }).then((res) => {
        this.PostList = res.data.list
        this.PostList.unshift({ postName: '无', id: '0' })
      })
    },

    //获取左侧部门树
    getDeptTree() {
      getDeptTree().then((res) => {
        this.DeptTree = res.data
        // this.DeptTree[0].id=1
        this.keys = ['1']
      })
    },
    // 选择上级部门
    MenucascaderSelectChange() {
      // 目的是选择之后将下拉界面收起
      this.$refs.cascaderHandle.toggleDropDownVisible()
      //获取最后一个元素
      if (this.addForm.deptId.length > 0) {
        this.addForm.deptId =
          this.addForm.deptId[this.addForm.deptId.length - 1]
      }
    },

    // 左侧部门节点点击
    DeptTreeNodeClick(data) {
      this.requestParams.deptId = data.id
      this.tableSearch()
    },
    doGetList() {
      this.listLoading = true
      findPostList(this.requestParams).then((data) => {
        this.tableData = data.data.list
        this.requestParams.pageNum = data.data.current
        this.tableData.forEach((item, index) => {
          item.number =
            (this.requestParams.pageNum - 1) * data.data.pageSize + index + 1
        })
        this.total = data.data.total
        this.listLoading = false
        this.FindPostList()
      })
    },

    // 分页跳转
    handleCurrentChange(val) {
      this.requestParams.pageNum = val
      this.doGetList()
    },

    //表格搜素
    tableSearch() {
      this.requestParams.pageNum = 1
      this.doGetList()
    },

    //清空搜索表单并搜索
    tableClear() {
      this.requestParams = {
        pageNum: 1,
        pageSize: 10,
        deptId: '',
        deptName: ''
      }
      this.doGetList()
      this.getDeptTree()
    },
    // 新增、编辑岗位
    doaddOrg() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.Infotitle === '新增岗位') {
            insertPost(this.addForm).then((res) => {
              if (res.status == 200) {
                this.$message({
                  message: res.message,
                  type: 'success'
                })
                this.operationorg = false
                this.doGetList()
              } else {
                this.$message.error(res.message)
              }
              this.loading = false
            })
          } else {
            this.addForm.id = this.postId
            this.postId = ''
            editPost(this.addForm).then((res) => {
              if (res.status == 200) {
                this.$message({
                  message: res.message,
                  type: 'success'
                })
                this.operationorg = false
                this.doGetList()
              } else {
                this.$message.error(res.message)
              }
              this.loading = false
            })
          }
        } else {
          return false
        }
      })
    },

    //清空查看详情
    InfotableClear() {
      this.addForm = {
        parentId: '',
        postName: '',
        deptId: '',
        manageFlag: ''
      }
    },

    // 删除岗位
    handleDlelte(index, row) {
      this.removeorg = true
      this.postId = row.id
    },
    removeOrg() {
      this.loading = true
      removePost({ id: this.postId }).then((res) => {
        if (res.status == 200) {
          this.$message({
            message: res.message,
            type: 'success'
          })
          this.postId = ''
          this.removeorg = false
          this.doGetList()
        } else {
          this.$message.error(res.message)
        }
        this.loading = false
      })
    },
    // 编辑岗位
    editOrg(row) {
      this.postId = row.id
      this.addForm.postName = row.postName
      this.addForm.deptId = row.deptId
      this.addForm.parentId = row.parentId
      this.addForm.manageFlag = row.manageFlag
    },

    //查询主管
    manageflag(row) {
      this.postId = row.id
      this.newtManage = row.postName

      queryDirector({ deptId: row.deptId }).then((res) => {
        if (res.status == 200) {
          if (res.data) {
            this.currentManage = res.data
          } else {
            this.currentManage = '无'
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    //设为主管
    editmanageFlag() {
      this.loading = true
      editDirector({ id: this.postId }).then((res) => {
        if (res.status == 200) {
          this.$message({
            message: res.message,
            type: 'success'
          })
          this.postId = ''
          this.manageFlag = false
          this.doGetList()
        } else {
          this.$message.error(res.message)
        }
        this.loading = false
      })
    }
  }
}
</script>


<style lang="scss">
.position-info {
  .manageFlag .el-form-item__label {
    width: 120px !important;
    margin-left: 0 !important;
  }

  .el-form-item__content {
    width: 200px;
  }

  .el-form-item__label {
    width: 115px;
  }

  .addForm .jzhynx .el-form-item__label {
    width: 80px;
    margin-left: 35px;
    line-height: 20px;
  }

  .left .el-tree-node.is-current > .el-tree-node__content {
    color: #409eff;
  }
}
</style>

<style lang="scss" scoped>
.left {
  float: left;
  width: 13%;
}

.el-tree {
  margin-left: 10px;
  margin-top: 10px;
  background-color: rgb(244 246 248);
  min-height: calc(100vh - 130px);
}

.right {
  float: left;
  width: 87%;
}

.el-form-item {
  margin: 20px 0;
  display: flex;
  float: left;
}

.head {
  overflow: hidden;
  margin: 30px 0;
}

.content {
}

.addForm .el-form-item {
  margin-right: 50px;
}
</style>
