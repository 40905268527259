<template>
  <div class="EmployeeInformation">
    <div class="left">
      <el-tree
        node-key="id"
        :expand-on-click-node="false"
        :default-expanded-keys="keys"
        :data="DeptTree"
        :props="DeptTreeProps"
        @node-click="DeptTreeNodeClick"
      ></el-tree>
    </div>
    <div class="right">
      <div class="head">
        <el-form class="info-form" auto-complete="on">
          <el-form-item label="员工名称：">
            <el-input
              v-model="requestParams.mixedTitle"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>

          <el-button
            @click="tableSearch"
            style="margin: 20px 10px 0 20px; float: left"
            type="primary"
            >搜索
          </el-button>
          <el-button
            @click="tableClear"
            style="margin: 20px 10px; float: left"
            type="primary"
            >清空
          </el-button>
          <el-button
            @click="
              Infotitle = '新增员工'
              operationorg = true
              InfotableClear()
            "
            style="margin: 20px 10px; float: left"
            v-if="hasPermission.includes('新增')"
            type="primary"
            >新增
          </el-button>
        </el-form>
      </div>
      <div class="content">
        <el-table
          :header-cell-style="{ textAlign: 'center' }"
          :cell-style="{ textAlign: 'center' }"
          :data="tableData"
          max-height="610"
          v-loading="listLoading"
          border
          style="width: 97%; margin: 0 30px"
        >
          <el-table-column prop="id" label="ID" v-if="false" width="60">
          </el-table-column>

          <el-table-column prop="number" label="序号" width="50">
          </el-table-column>

          <el-table-column
            :show-overflow-tooltip="true"
            prop="personRealName"
            label="姓名"
          >
          </el-table-column>

          <el-table-column
            :show-overflow-tooltip="true"
            prop="personMobile"
            label="手机号码"
          >
          </el-table-column>

          <el-table-column
            :show-overflow-tooltip="true"
            prop="deptName"
            label="所在部门"
          >
          </el-table-column>

          <el-table-column
            :show-overflow-tooltip="true"
            prop="postName"
            label="当前岗位"
          >
          </el-table-column>

          <el-table-column
            :show-overflow-tooltip="true"
            prop="assetsRoleName"
            label="当前角色"
          >
          </el-table-column>

          <el-table-column
            :show-overflow-tooltip="true"
            prop="remarks"
            label="备注信息"
          >
          </el-table-column>

          <el-table-column label="操作" width="500">
            <template slot-scope="scope">
              <el-button
                size="mini"
                v-if="hasPermission.includes('分配权限')"
                @click="
                  PersonRole(scope.row)
                  personrole = true
                "
                >分配权限
              </el-button>

              <el-button
                size="mini"
                v-if="hasPermission.includes('工作交接')"
                @click="
                  handover = true
                  userId = scope.row.id
                "
                >工作交接
              </el-button>

              <el-button
                size="mini"
                v-if="hasPermission.includes('编辑')"
                @click="
                  operationorg = true
                  editUser(scope.row)
                  Infotitle = '编辑员工'
                "
                >编辑
              </el-button>

              <el-button
                size="mini"
                v-if="hasPermission.includes('删除')"
                @click="handleDlelte(scope.$index, scope.row)"
                >删除
              </el-button>

              <el-button
                size="mini"
                v-if="hasPermission.includes('重置密码')"
                @click="
                  Resetpwd = true
                  userId = scope.row.id
                "
                >重置密码
              </el-button>
              <el-button
                size="mini"
                v-show="
                  scope.row.identityFlag == null ||
                  scope.row.identityFlag.indexOf('1') == -1
                "
                v-if="hasPermission.includes('课程顾问')"
                @click="
                  operationstudent = true
                  userId = scope.row.id
                "
                >设置课程顾问
              </el-button>
              <el-button
                size="mini"
                v-show="
                  scope.row.identityFlag &&
                  scope.row.identityFlag.indexOf('1') !== -1
                "
                v-if="hasPermission.includes('课程顾问')"
                @click="
                  removeteacher = true
                  userId = scope.row.id
                "
                >取消课程顾问
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="text-align: center; margin: 10px">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            layout="prev, pager, next"
            :page-size="requestParams.pageSize"
            :current-page="requestParams.pageNum"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!--    弹出框-->
    <el-dialog title="删除员工" :visible.sync="removeuser" width="25%">
      <div>确定要删除该员工吗？</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="removeuser = false">取 消</el-button>
        <el-button type="primary" @click="removeUser()">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="分配权限" :visible.sync="personrole" width="25%">
      <el-form style="overflow: hidden">
        <el-form-item class="personrole" label="请选择权限">
          <el-select v-model="roleIds" multiple placeholder="请选择">
            <el-option
              v-for="item in PersonRoleList"
              :label="item.roleName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            personrole = false
            roleIds = ''
          "
          >取 消</el-button
        >
        <el-button type="primary" :loading="loading" @click="addPersonRole()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog title="工作交接" :visible.sync="handover" width="25%">
      <el-form style="overflow: hidden">
        <el-form-item class="hanDover" label="请选择接收员工">
          <el-select v-model="hanDoverId" placeholder="请选择">
            <el-option
              v-for="item in hanDoverList"
              :label="item.personRealName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handover = false">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="Handover()"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      :title="Infotitle"
      :visible.sync="operationorg"
      @close="
        $refs.addForm.clearValidate()
        selecttkey = false
      "
      @open="selecttkey = true"
      width="25%"
    >
      <div>
        <el-form
          :model="addForm"
          :rules="addFormrules"
          ref="addForm"
          class="addForm"
          style="
            overflow: hidden;
            padding-left: 30px;
            display: flex;
            flex-wrap: wrap;
          "
        >
          <el-form-item label="员工姓名" prop="personRealName">
            <el-input
              v-model="addForm.personRealName"
              placeholder="请输入"
            ></el-input>
          </el-form-item>

          <el-form-item label="手机号码" prop="personMobile">
            <el-input
              v-model="addForm.personMobile"
              placeholder="请输入"
            ></el-input>
          </el-form-item>

          <el-form-item label="所在部门" prop="personDeptId">
            <el-cascader
              v-if="selecttkey"
              @change="MenucascaderSelectChange"
              ref="cascaderHandle"
              filterable
              :props="{
                checkStrictly: true,
                multiple: true,
                children: 'childList',
                label: 'orgName',
                value: 'id'
              }"
              :options="DeptTree"
              v-model="addForm.deptArryStr"
              :show-all-levels="false"
            ></el-cascader>
          </el-form-item>

          <el-form-item label="当前岗位" prop="postId">
            <el-select
              multiple
              filterable
              v-model="addForm.postId"
              @change="postChange"
              placeholder="请选择"
            >
              <el-option
                v-for="item in PostList"
                :label="item.postName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="上级负责人" prop="parentId">
            <el-select
              filterable
              v-model="addForm.parentId"
              placeholder="请选择"
            >
              <el-option
                v-for="item in parentList"
                :label="item.personRealName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="operationorg = false">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="doaddUser()"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog title="重置密码" :visible.sync="Resetpwd" width="25%">
      <div>确定要重置密码吗？</div>
      <div>重置后的新密码为：qc+手机号后6位</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="Resetpwd = false">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="ResetPWD()"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="设置课程顾问"
      :visible.sync="operationstudent"
      @close="$refs.CourseForm.clearValidate()"
      width="30%"
    >
      <div>
        <el-form
          :model="CourseForm"
          :rules="CourseFormrules"
          ref="CourseForm"
          class="addForm"
          style="overflow: hidden; padding-left: 30px"
        >
          <el-form-item label="昵称" prop="personName">
            <el-input
              v-model="CourseForm.personName"
              placeholder="请输入"
            ></el-input>
          </el-form-item>

          <!--          <el-form-item
                      label="线上推送"
                      prop="onlineConsultant"
                    >
                      <el-radio-group v-model="CourseForm.onlineConsultant">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                      </el-radio-group>
                    </el-form-item>-->

          <el-form-item label="二维码" prop="imageUrl">
            <el-upload
              style="text-align: center"
              class="avatar-uploader"
              ref="upload"
              :action="imgURL"
              accept=".png,.jpg,.jpeg"
              :show-file-list="false"
              :file-list="fileList"
              :on-success="uploadSuccess"
              :on-change="localPathGet"
              :auto-upload="false"
              :before-upload="beforeUpload"
            >
              <img
                style="max-width: 180px; max-height: 180px"
                v-if="imageUrl"
                :src="imageUrl"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <div style="color: red; margin-top: -10px; font-size: 12px">
              图片尺寸大小：180*180
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            operationstudent = false
            clearCourseForm()
          "
          >取 消</el-button
        >
        <el-button type="primary" :loading="loading" @click="doaddTeacher()"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog title="取消课程顾问" :visible.sync="removeteacher" width="25%">
      <div>确定要取消该课程顾问吗？</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="removeteacher = false">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="removeTeacher()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  deletedConsultant,
  addPersonRole,
  addUser,
  delUser,
  editUser,
  findPostList,
  getDeptTree,
  Handover,
  queryList,
  queryParentPerson,
  rolePage,
  insertConsultant
} from '@/api/organization'
import { validPhone } from '@/utils/validate'
import { findRoleMenuButton, ResetPWD } from '@/api/user'

export default {
  name: 'EmployeeInformation',
  data() {
    const validateuserPhone = (rule, value, callback) => {
      if (!validPhone(value)) {
        callback(new Error('请输入正确的手机号码'))
      } else {
        callback()
      }
    }
    return {
      removeteacher: false,
      imgURL: '',
      fileList: [],
      imageUrl: '',
      CourseForm: {
        id: '',
        personName: '',
        onlineConsultant: '',
        qrCode: '',
        imageUrl: ''
      },
      CourseFormrules: {
        personName: [
          { required: true, message: '请输入昵称', trigger: ['blur'] },
          { min: 2, max: 10, message: '请输入2-10个字符', trigger: ['blur'] }
        ],
        imageUrl: [
          { required: true, message: '请上传二维码', trigger: ['blur'] }
        ]
      },
      userId: '',
      operationstudent: false,
      selecttkey: true,
      hasPermission: [],
      Infotitle: '',
      keys: ['1'],
      DeptTree: [],
      DeptTreeProps: {
        children: 'childList',
        label: 'orgName'
      },
      listLoading: false,
      //表格
      tableData: [],
      requestParams: {
        pageNum: 1,
        pageSize: 10,
        personDeptId: '',
        mixedTitle: ''
      },
      total: 0,
      //按钮
      operationorg: false,

      //员工信息新增
      addForm: {
        personMobile: '',
        personRealName: '',
        deptArryStr: '',
        postId: '',
        parentId: ''
      },
      addFormrules: {
        personRealName: [
          { required: true, message: '请输入员工姓名', trigger: ['blur'] },
          { min: 2, max: 10, message: '请输入2-10个字符', trigger: ['blur'] }
        ],
        personMobile: [
          {
            required: true,
            message: '请输入正确的手机号码',
            trigger: ['blur'],
            validator: validateuserPhone
          }
        ],
        deptArryStr: [
          { required: true, message: '请选择所在部门', trigger: ['blur'] }
        ],
        postId: [{ required: true, message: '请选择岗位', trigger: ['blur'] }],
        parentId: [
          { required: true, message: '请选择上级负责人', trigger: ['blur'] }
        ]
      },
      // 删除员工
      removeuser: false,

      //主管弹出层
      manageFlag: false,

      //当前主管
      currentManage: '',
      //新主管
      newtManage: '',
      PostList: '',

      // 重置密码
      Resetpwd: false,

      //角色列表
      personrole: false,
      PersonRoleList: '',
      roleIds: '',
      loading: false,

      //工作交接
      handover: false,
      hanDoverId: '',
      hanDoverList: '',

      parentList: [],
      deptParmList: []
    }
  },
  mounted() {
    this.imgURL = sessionStorage.getItem('imgURL')
  },
  created() {
    this.getDeptTree()
    this.doGetList()
    findRoleMenuButton({ name: 'EmployeeInformation' }).then((res) => {
      this.hasPermission = res.data
    })
    //获取岗位列表
    findPostList({
      pageNum: 1,
      pageSize: 99999
    }).then((res) => {
      this.PostList = res.data.list
    })

    //获取角色列表
    rolePage({
      pageNum: 1,
      pageSize: 99999
    }).then((res) => {
      this.PersonRoleList = res.data.list
    })
    //获取所有员工列表
    queryList({
      pageNum: 1,
      pageSize: 99999
    }).then((res) => {
      this.hanDoverList = res.data.list
    })
  },
  methods: {
    removeTeacher() {
      this.loading = true
      deletedConsultant(this.userId).then((res) => {
        if (res.status == 200) {
          this.$message({
            message: res.message,
            type: 'success'
          })
          this.userId = ''
          this.removeteacher = false
          this.doGetList()
        } else {
          this.$message.error(res.message)
        }
        this.loading = false
      })
    },
    //上传后回调，保存方法在其内
    uploadSuccess(res) {
      if (res.status == 200) {
        this.CourseForm.qrCode = res.data
      } else {
        this.$message.error(res.message)
        return
      }
      this.saveCourse()
    },
    //图片上传前
    beforeUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG 或 PNG 格式!')
      }
      if (isJPG) {
        return isJPG
      }
    },
    //本地-图片路径
    localPathGet(event, fileList) {
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]] // 这一步，是 展示最后一次选择的csv文件
      }
      let url = ''
      if (window.createObjectURL != undefined) {
        url = window.createObjectURL(event.raw)
      } else if (window.URL != undefined) {
        url = window.URL.createObjectURL(event.raw)
      } else if (window.webkitURL != undefined) {
        url = window.webkitURL.createObjectURL(event.raw)
      }
      this.imageUrl = url
      this.CourseForm.imageUrl = url
    },
    // 添加课程顾问提交
    doaddTeacher() {
      this.$refs.CourseForm.validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.imageUrl.substring(0, 4) === 'blob') {
            this.$refs.upload.submit()
          } else {
            this.saveCourse()
          }
        } else {
          return false
        }
      })
    },
    saveCourse() {
      this.CourseForm.id = this.userId
      console.log(this.CourseForm)
      insertConsultant(this.CourseForm).then((res) => {
        if (res.status == 200) {
          this.$message({
            message: res.message,
            type: 'success'
          })
          this.operationstudent = false

          this.doGetList()
          this.clearCourseForm()
        } else {
          this.$message.error(res.message)
        }
        this.loading = false
      })
    },
    //获取左侧部门树
    getDeptTree() {
      getDeptTree().then((res) => {
        this.DeptTree = res.data
        // this.DeptTree[0].id=1
        this.keys = ['1']
      })
    },
    // 选择上级部门
    MenucascaderSelectChange(value) {
      this.deptParmList = []
      value.forEach((item) => {
        this.deptParmList.push(item[item.length - 1])
      })
    },

    // 左侧部门节点点击
    DeptTreeNodeClick(data) {
      this.requestParams.personDeptId = data.id
      this.tableSearch()
    },
    doGetList() {
      this.listLoading = true
      queryList(this.requestParams).then((data) => {
        this.tableData = data.data.list
        this.requestParams.pageNum = data.data.current
        this.tableData.forEach((item, index) => {
          item.number =
            (this.requestParams.pageNum - 1) * data.data.pageSize + index + 1
        })
        this.total = data.data.total
        this.listLoading = false
      })
    },

    // 分页跳转
    handleCurrentChange(val) {
      this.requestParams.pageNum = val
      this.doGetList()
    },

    //表格搜素
    tableSearch() {
      this.requestParams.pageNum = 1
      this.doGetList()
    },

    //清空搜索表单并搜索
    tableClear() {
      this.requestParams = {
        pageNum: 1,
        pageSize: 10,
        personDeptId: '',
        mixedTitle: ''
      }
      this.doGetList()
      this.getDeptTree()
    },
    // 新增、编辑员工
    doaddUser() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          if (this.deptParmList.length == 0) {
            this.addForm.personDeptId = ''
          }
          if (this.deptParmList && this.deptParmList.length !== 0) {
            this.addForm.personDeptId = this.deptParmList.join(',')
          }
          if (this.addForm.postId) {
            this.addForm.postId = this.addForm.postId.join(',')
          }
          this.loading = true
          if (this.Infotitle === '新增员工') {
            addUser(this.addForm).then((res) => {
              if (res.status == 200) {
                this.$message({
                  message: res.message,
                  type: 'success'
                })
                this.operationorg = false
                this.doGetList()
              } else {
                this.$message.error(res.message)
              }
              this.loading = false
            })
          } else {
            this.addForm.id = this.userId
            this.userId = ''
            editUser(this.addForm).then((res) => {
              if (res.status == 200) {
                this.$message({
                  message: res.message,
                  type: 'success'
                })
                this.operationorg = false
                this.doGetList()
              } else {
                this.$message.error(res.message)
              }
              this.loading = false
            })
          }
        } else {
          return false
        }
      })
    },
    clearCourseForm() {
      this.fileList = []
      this.imageUrl = ''
      this.CourseForm = {
        id: '',
        personName: '',
        onlineConsultant: '',
        qrCode: '',
        imageUrl: ''
      }
    },
    //清空查看详情
    InfotableClear() {
      this.addForm = {
        personMobile: '',
        personRealName: '',
        personDeptId: '',
        deptArryStr: '',
        postId: '',
        parentId: ''
      }
    },

    // 删除员工
    handleDlelte(index, row) {
      this.removeuser = true
      this.userId = row.id
    },
    removeUser() {
      this.loading = true
      delUser({ id: this.userId, isDeleted: 1 }).then((res) => {
        if (res.status == 200) {
          this.$message({
            message: res.message,
            type: 'success'
          })
          this.userId = ''
          this.removeuser = false
          this.doGetList()
        } else {
          this.$message.error(res.message)
        }
        this.loading = false
      })
    },
    // 编辑员工
    editUser(row) {
      this.userId = row.id
      this.addForm.personRealName = row.personRealName
      this.addForm.personMobile = row.personMobile
      this.addForm.deptArryStr = row.deptArryStr
      this.addForm.postId = row.postId.split(',')
      this.addForm.parentId = row.parentId
      this.addForm.personDeptId = row.personDeptId
      this.deptParmList = []
      this.deptParmList.push(row.personDeptId)
      queryParentPerson({ postId: row.postId, id: this.userId }).then((res) => {
        if (res.status === 200) {
          this.parentList = res.data

          if (this.parentList.length === 0) {
            this.parentList.unshift({ personRealName: '无', id: '0' })
            if (this.addForm.parentId !== '0') {
              this.addForm.parentId = ''
            }
          } else if (
            this.addForm.parentId === '0' &&
            this.parentList.length !== 0
          ) {
            this.addForm.parentId = ''
          } else if (this.addForm.parentId !== '0') {
            let flag = false
            this.parentList.forEach((item) => {
              if (item.id === this.addForm.parentId) {
                flag = true
              }
            })
            if (!flag) {
              this.addForm.parentId = ''
            }
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },

    //重置密码
    ResetPWD() {
      this.loading = true
      ResetPWD({ id: this.userId, userType: '2' }).then((res) => {
        if (res.status == 200) {
          this.$message({
            message: res.message,
            type: 'success'
          })
          this.userId = ''
          this.Resetpwd = false
          this.doGetList()
        } else {
          this.$message.error(res.message)
        }
        this.loading = false
      })
    },

    //分配权限
    addPersonRole() {
      if (this.roleIds.length == 0) {
        this.$message.error('请选择权限')
        return
      }
      this.loading = true
      this.roleIds = this.roleIds.map((item) => item).join(',')
      addPersonRole({ id: this.userId, roleIds: this.roleIds }).then((res) => {
        if (res.status == 200) {
          this.$message({
            message: res.message,
            type: 'success'
          })
          this.userId = ''
          this.roleIds = ''
          this.personrole = false
          this.doGetList()
        } else {
          this.$message.error(res.message)
        }
        this.loading = false
      })
    },

    PersonRole(row) {
      this.userId = row.id
      console.log(row)
      if (row.roleIds) {
        this.roleIds = row.roleIds.split(',')
      }
    },

    //交换数据
    Handover() {
      this.loading = true
      Handover({ id: this.userId, hanDoverId: this.hanDoverId }).then((res) => {
        if (res.status == 200) {
          this.$message({
            message: res.message,
            type: 'success'
          })
          this.userId = ''
          this.hanDoverId = ''
          this.handover = false
          this.doGetList()
        } else {
          this.$message.error(res.message)
        }
        this.loading = false
      })
    },
    postChange(val) {
      this.addForm.parentId = ''
      let postIdstr = val.join(',')
      queryParentPerson({ postId: postIdstr, id: this.userId }).then((res) => {
        if (res.status === 200) {
          this.parentList = res.data
          if (this.parentList.length === 0) {
            this.parentList.unshift({ personRealName: '无', id: '0' })
          }
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>


<style lang="scss" scoped>
.hanDover .el-form-item__label {
  width: 130px !important;
  margin-left: 30px;
}

.personrole .el-form-item__label {
  width: 130px !important;
  margin-left: 30px;
}

.el-form-item__content {
  width: 200px;
}

.el-form-item__label {
  width: 115px;
}

.addForm .jzhynx .el-form-item__label {
  width: 280px;
  margin-left: 35px;
  line-height: 20px;
}

.left .el-tree-node.is-current > .el-tree-node__content {
  color: #409eff;
}
</style>

<style lang="scss" scoped>
.left {
  float: left;
  width: 13%;
}

.el-tree {
  margin-left: 10px;
  margin-top: 10px;
  background-color: rgb(244 246 248);
  min-height: calc(100vh - 130px);
}

.right {
  float: left;
  width: 87%;
}

.el-form-item {
  margin: 20px 0;
  display: flex;
  float: left;
}

.head {
  overflow: hidden;
  margin: 30px 0;
}

.content {
}

.addForm .el-form-item {
  margin-right: 50px;
}

.avatar-uploader-icon {
  height: 130px;
  line-height: 130px;
  width: 130px;
}
</style>
<style>
.EmployeeInformation .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
</style>
