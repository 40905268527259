// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "", ""]);
// Exports
exports.locals = {
	"menuText": "#fff",
	"menuActiveText": "#409EFF",
	"subMenuActiveText": "#409EFF",
	"menuBg": "#011629",
	"menuHover": "#073156",
	"subMenuBg": "#000C18",
	"subMenuHover": "#073156",
	"sideBarWidth": "210px"
};
module.exports = exports;
