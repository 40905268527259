<template>
  <div>
    <div style="display: flex">
      <el-date-picker
        v-model="monthsArr"
        type="monthrange"
        range-separator="至"
        start-placeholder="开始月份"
        end-placeholder="结束月份"
        value-format="yyyy-MM"
      >
      </el-date-picker>
      <Query :show-daterange="false" @search="handleSearch"></Query>
      <export-excel
        v-if="$store.state.common.hasPermission.includes('导出')"
        :requestParams="queryForm"
        action="/manage/wechat/data/monthContrastStatistics"
        :columns="columns"
        filename="数据统计-月度统计"
      ></export-excel>
    </div>
    <table-components
      :columns="columns"
      :total="total"
      :pageNum="pageNum"
      :pageSize="pageSize"
      :data="tableData"
      v-loading="loading"
      @get-list="getList"
    ></table-components>
  </div>
</template>
<script>
import { monthContrastStatistics } from '@/api/statistics'
import TableComponents from '@/components/table-components.vue'
let staticColumns = [
  {
    label: '所在部门',
    prop: 'deptName',
    width: '150',
    fixed: 'left'
  },
  {
    label: '课程顾问',
    prop: 'personName',
    width: '120',
    fixed: 'left'
  },
  {
    label: '账号',
    prop: 'wechatNum',
    width: '120',
    fixed: 'left'
  }
]
import Query from './components/query.vue'
export default {
  components: {
    Query,
    TableComponents
  },
  data() {
    return {
      staticColumns,
      columns: [],
      queryForm: {},
      pageNum: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      loading: false,
      monthsArr: []
    }
  },
  created() {
    this.$store.dispatch('findRoleMenuButtonFn', 'StatisticsContrast')
    this.monthsArr = [
      this.publicMethods.recentTime(-60, 'yyyy-MM'),
      this.publicMethods.recentTime(0, 'yyyy-MM')
    ]
  },
  methods: {
    // 获取当前月的下一个月份 需求要求查询起始月-结束月 包括结束月,后端接口查询的是不包括结束月，所以endTime传参需要向后推下一个月
    getNextMonth(date) {
      let year = parseInt(date.split('-')[0])
      let month = parseInt(date.split('-')[1])
      if (month == 12) {
        month = 1
        year = parseInt(year) + 1
      } else {
        month += 1
      }
      if (month < 10) {
        month = year + '-' + '0' + month
      }
      return month
    },
    handleSearch(queryForm) {
      this.queryForm = queryForm
      this.queryForm.modeType = 'C'
      this.queryForm.pageNum = this.pageNum
      this.queryForm.pageSize = this.pageSize
      if (this.publicMethods.isEmpty(this.monthsArr)) {
        this.queryForm.startTime = ''
        this.queryForm.endTime = ''
      } else {
        this.queryForm.startTime = this.monthsArr[0]
        this.queryForm.endTime = this.getNextMonth(this.monthsArr[1])
      }
      console.log(this.queryForm, this.monthsArr[0], this.monthsArr[1])
      // 获取月份之间的表头
      this.getColumns()
      this.getList(1)
    },
    getColumns() {
      let months = [this.monthsArr[0], this.monthsArr[1]]

      let dynamicsColumns = [
        {
          label: months[0] + '好友总数',
          prop: 'totalFriendNum1',
          width: 140
        },
        {
          label: months[0] + '新增好友数',
          prop: 'addFriendNum1',
          width: 150
        },
        {
          label: months[0] + '删除好友数',
          prop: 'delFriendNum1',
          width: 150
        },
        {
          label: months[0] + '净增好友数',
          prop: 'netGrowthFriendNum1',
          width: 150
        },
        {
          label: months[months.length - 1] + '好友总数',
          prop: 'totalFriendNum2',
          width: 150
        },
        {
          label: months[months.length - 1] + '较上月增加(个)',
          prop: 'totalLastNum2',
          width: 170
        },
        {
          label: months[months.length - 1] + '较上月增加(%)',
          prop: 'totalLastPercent2',
          width: 170
        },
        {
          label: months[months.length - 1] + '新增',
          prop: 'addFriendNum2',
          width: 150
        },
        {
          label: months[months.length - 1] + '较上月增加(个)',
          prop: 'addLastNum2',
          width: 170
        },
        {
          label: months[months.length - 1] + '较上月增加(%)',
          prop: 'addLastPercent2',
          width: 170
        },
        {
          label: months[months.length - 1] + '删除',
          prop: 'delFriendNum2',
          width: 150
        },
        {
          label: months[months.length - 1] + '较上月增加(个)',
          prop: 'delLastNum2',
          width: 170
        },
        {
          label: months[months.length - 1] + '较上月增加(%)',
          prop: 'delLastPercent2',
          width: 170
        },
        {
          label: months[months.length - 1] + '净增',
          prop: 'netGrowthFriendNum2',
          width: 150
        },
        {
          label: months[months.length - 1] + '较上月增加(个)',
          prop: 'netGrowthLastNum2',
          width: 170
        },
        {
          label: months[months.length - 1] + '较上月增加(%)',
          prop: 'netGrowthLastPercent2',
          width: 170
        }
      ]
      this.columns = this.staticColumns.concat(dynamicsColumns)
    },
    getList(pageNum) {
      if (pageNum) {
        this.queryForm.pageNum = pageNum
      }
      this.loading = true
      monthContrastStatistics(this.queryForm).then((res) => {
        this.loading = false
        if (res.status == 200) {
          this.tableData = res.data.list
          this.total = res.data.total
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
</style>