var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticStyle: { display: "flex" } },
        [
          _c("el-date-picker", {
            attrs: {
              type: "monthrange",
              "range-separator": "至",
              "start-placeholder": "开始月份",
              "end-placeholder": "结束月份",
              "value-format": "yyyy-MM"
            },
            model: {
              value: _vm.monthsArr,
              callback: function($$v) {
                _vm.monthsArr = $$v
              },
              expression: "monthsArr"
            }
          }),
          _c("Query", {
            attrs: { "show-daterange": false },
            on: { search: _vm.handleSearch }
          }),
          _vm.$store.state.common.hasPermission.includes("导出")
            ? _c("export-excel", {
                attrs: {
                  requestParams: _vm.queryForm,
                  action: "/manage/wechat/data/monthContrastStatistics",
                  columns: _vm.columns,
                  filename: "数据统计-月度统计"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c("table-components", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading"
          }
        ],
        attrs: {
          columns: _vm.columns,
          total: _vm.total,
          pageNum: _vm.pageNum,
          pageSize: _vm.pageSize,
          data: _vm.tableData
        },
        on: { "get-list": _vm.getList }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }