<template>
  <div class="navbar">
    <hamburger
      :is-active="sidebar.opened"
      class="hamburger-container"
      @toggleClick="toggleSideBar"
    />

    <breadcrumb class="breadcrumb-container" />

    <div class="right-menu">
      <router-link
        v-if="false"
        @click.native="
          stateSwitch(0)
          message = false
        "
        to="/PersonalCenter/Message"
      >
        <div
          style="
            position: relative;
            display: inline-block;
            margin-right: 20px;
            vertical-align: super;
            cursor: pointer;
          "
        >
          <!-- <img style="width: 24px" src="@/assets/消息.png" alt="" /> -->
          <i
            class="el-icon-message-solid"
            style="font-size: 26px; color: #409eff"
          ></i>
          <div
            v-show="message"
            style="
              position: absolute;
              top: 0px;
              font-size: 12px;
              color: rgb(255, 255, 255);
              background-color: red;
              width: 12px;
              height: 12px;
              border-radius: 50%;
              left: 12px;
              line-height: 20px;
              text-align: center;
            "
          ></div>
        </div>
      </router-link>

      <el-dropdown class="avatar-container" trigger="click">
        <div class="avatar-wrapper">
          <img
            :src="avatar ? avatar : photpsrc + '?imageView2/1/w/80/h/80'"
            class="user-avatar"
          />
          <i class="el-icon-caret-bottom" />
        </div>
        <el-dropdown-menu slot="dropdown" class="user-dropdown">
          <router-link
            @click.native="stateSwitch(state)"
            v-show="state == 1"
            to="/dashboard"
          >
            <el-dropdown-item> 主页 </el-dropdown-item>
          </router-link>

          <el-dropdown-item
            v-show="state == 0"
            @click.native="turnStation(state)"
          >
            启程在线
          </el-dropdown-item>

          <el-dropdown-item divided @click.native="logout">
            <span style="display: block">退出</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'
import { queryUnreadNum } from '@/api/PersonalCenter'
import store from '@/store'
export default {
  inject: ['reload'],
  data() {
    return {
      state: '0',
      message: false,
      messagelength: 0,
      photpsrc: require('@/assets/xiaoqi-head.png')
    }
  },
  watch: {
    updateMsg() {
      if (store.getters.socket.indexOf('2') !== -1) {
        this.getqueryUnreadNum()
        window.localStorage.setItem('messageFlag', 'true')
      }
    },
    updateMsgtime() {
      this.message = false
      window.localStorage.setItem('messageFlag', 'false')
    }
  },
  mounted() {
    if (window.sessionStorage.getItem('menuSate') == 0) {
      this.state = 1
    } else {
      this.state = 0
    }
    if (window.localStorage.getItem('messageFlag') === 'true') {
      this.message = true
    }
  },
  components: {
    Breadcrumb,
    Hamburger
  },
  computed: {
    updateMsg() {
      return store.state.user.sockettime
    },
    updateMsgtime() {
      return store.state.user.messagetime
    },
    ...mapGetters(['sidebar', 'avatar'])
  },
  methods: {
    getqueryUnreadNum() {
      this.message = true
      // this.messagelength=data.data
      this.$message('您收到一条新的消息')
      /*   this.sound = new Audio();

      this.sound.src = '提示音文件地址';

      this.sound.play();*/
    },
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    turnStation() {
      window.open(process.env.VUE_APP_PORTAL_URL, '_blank')
    },
    stateSwitch(state) {
      if (state == 0) {
        //个人中心
        this.state = 1
        window.sessionStorage.setItem('menuSate', '0')
        this.reload()
      } else {
        this.state = 0
        window.sessionStorage.setItem('menuSate', '1')
        this.reload()
      }
    },
    async logout() {
      await this.$store.dispatch('user/logout')
      global.antRouter = null
      this.$router.push(`/login`)
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .right-menu {
    position: absolute;
    right: 0;
    top: 0px;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-right: 6px;
        }

        .el-icon-caret-bottom {
          color: #fff;
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 14px;
          font-size: 18px;
        }
      }
    }
  }
}
</style>
