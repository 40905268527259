<template>
  <div class="disused">
    <div class="head">
      <list-query
        :show-filter="false"
        :show-add="false"
        :show-other="false"
        @search="tableSearch"
      >
        <template slot="default">
          <el-form :model="queryForm">
            <el-form-item>
              <el-input
                v-model="queryForm.wechatNum"
                placeholder="请选择微信号"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="queryForm.bindPhone"
                oninput="this.value = this.value.replace(/[^\d.]/g,'');"
                placeholder="请选择绑定手机号"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-select
                v-model="userIds"
                multiple
                filterable
                clearable
                placeholder="请选择使用人"
              >
                <el-option
                  v-for="item in userList"
                  :label="item.personRealName"
                  :key="item.id"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select
                v-model="attributeIds"
                multiple
                filterable
                clearable
                placeholder="请选择微信号属性"
              >
                <el-option
                  v-for="item in attrPages"
                  :label="item.attributeName"
                  :key="item.id"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </template>
        <div slot="default-btn">
          <export-excel
            v-if="$store.state.common.hasPermission.includes('导出')"
            :requestParams="queryForm"
            action="/manage/wechat/assets/qryPage"
            :columns="columns"
            filename="资产列表"
          ></export-excel>
        </div>
      </list-query>
    </div>
    <table-components
      :columns="columns"
      :total="total"
      :pageNum="queryForm.pageNum"
      :pageSize="queryForm.pageSize"
      :data="tableData"
      v-loading="loading"
      @get-list="doGetList"
    >
      <el-table-column label="操作" width="120px" slot="operate" fixed="right">
        <template slot-scope="scope"
          ><el-button
            size="mini"
            type="primary"
            v-if="$store.state.common.hasPermission.includes('删除')"
            @click="
              dataId = scope.row.id
              deleteObject = true
            "
            >删除
          </el-button></template
        >
      </el-table-column>
    </table-components>

    <!--    删除-->
    <el-dialog title="温馨提示" :visible.sync="deleteObject" width="25%">
      <div>确定要删除该微信号吗？</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteObject = false">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="removeObject()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  deleteInfo,
  qryPage,
  saveInfo,
  scrapObj,
  recoveryObj,
  changeObj
} from '@/api/assetsList'
import { attrPage, ExceptionalPage } from '@/api/labelManagement'
import { queryList } from '@/api/organization'

let columns = [
  {
    label: '微信号',
    prop: 'wechatNum',
    fixed: true,
    width: 120
  },
  {
    label: '微信好友数',
    prop: 'friendNum',
    width: 120
  },
  {
    label: '报废前好友数',
    prop: 'scrapFriendNum',
    width: 120
  },
  {
    label: '微信状态',
    prop: 'wechatStatusName',
    width: 120
  },
  {
    label: '使用人',
    prop: 'userName',
    width: 120
  },
  {
    label: '所在部门',
    prop: 'deptName',
    width: 180
  },
  {
    label: '微信昵称',
    prop: 'nickName',
    width: 120,
    showTooltip: true
  },
  {
    label: '登录方式',
    prop: 'loginMethod',
    width: 120
  },
  {
    label: '登录密码',
    prop: 'loginPassword',
    hidden: true
  },
  {
    label: '实名认证信息',
    prop: 'realName',
    width: 120,
    showTooltip: true
  },
  {
    label: '开户行',
    prop: 'bankName'
  },
  {
    label: '银行卡号',
    width: 200,
    prop: 'bankNo'
  },
  {
    label: '可修改微信号时间',
    prop: 'editWechatDate',
    width: 150
  },
  {
    label: '绑定QQ',
    prop: 'qqBind',
    width: 150
  },
  {
    label: 'QQ密码',
    prop: 'qqPwd',
    width: 150
  },
  {
    label: '绑定手机号',
    prop: 'bindPhone',
    width: 150
  },
  {
    label: '手机号使用人',
    prop: 'phoneUsingName',
    width: 150
  },
  {
    label: '微信号属性',
    prop: 'attributeName',
    width: 150
  },
  {
    label: '报废时间',
    prop: 'scrapTime',
    width: 170
  },
  {
    label: '报废原因',
    prop: 'scrapReason',
    showTooltip: true
  },
  {
    label: '报废人',
    prop: 'scrapPersonName',
    width: 150
  }
]
export default {
  data() {
    return {
      userList: [],
      attrPages: [],
      userIds: [],
      attributeIds: [],
      columns: columns,
      dialogVisible: false,
      InfoTitle: '',
      queryForm: {
        pageNum: 1,
        pageSize: 10,
        wechatNum: '',
        bindPhone: '',
        userIds: '',
        attributeIds: ''
      },
      total: 0,
      tableData: [],
      abnormalDialog: false,
      abnormalForm: {},
      deleteObject: false,
      dataId: null,
      loading: false
    }
  },
  created() {
    this.$store.dispatch('findRoleMenuButtonFn', 'assetsListDisused')
    // 微信号属性
    attrPage({
      pageNum: 1,
      deletedFlag: 2,
      pageSize: 9999999
    }).then((data) => {
      this.attrPages = data.data.list
    })
    // 用户列表
    queryList({
      pageNum: 1,
      pageSize: 9999999
    }).then((data) => {
      this.userList = data.data.list
    })
    this.doGetList()
  },
  methods: {
    // 分页跳转
    handleCurrentChange(val) {
      this.queryForm.pageNum = val
      this.doGetList()
    },
    tableSearch() {
      this.queryForm.pageNum = 1
      this.doGetList()
    },
    doGetList() {
      this.loading = true
      this.queryForm.wechatStatus = 2
      this.queryForm.userIds = this.userIds.join(',')
      this.queryForm.attributeIds = this.attributeIds.join(',')
      qryPage(this.queryForm).then((data) => {
        this.tableData = data.data.list
        this.queryForm.pageNum = data.data.current
        this.total = data.data.total
        this.loading = false
      })
    },
    removeObject() {
      this.loading = true
      deleteInfo({ id: this.dataId }).then((res) => {
        if (res.status == 200) {
          this.$message({
            message: res.message,
            type: 'success'
          })
          this.dataId = ''
          this.deleteObject = false
          this.doGetList()
        } else {
          this.$message.error(res.message)
        }
        this.loading = false
      })
    }
  }
}
</script>
<style lang="scss">
.disused {
  .el-form-item {
    margin-right: 20px;
  }

  .el-form-item,
  .el-form-item__label,
  .el-form-item__content {
    display: inline-block;
  }

  .el-form-item__content {
    margin-left: 0;
    width: 200px;
  }
}
</style>
<style lang="scss" scoped>
::v-deep .table-input .el-input__inner {
  text-align: center;
}

::v-deep .abnormal-dialog {
  .el-form-item__label {
    width: 120px;
  }
  .el-select {
    width: 200px;
  }

  .el-input__inner {
    width: 200px;
  }

  .el-textarea {
    width: 540px;
  }
}
</style>
