var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar }
      }),
      _c("breadcrumb", { staticClass: "breadcrumb-container" }),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          false
            ? _c(
                "router-link",
                {
                  attrs: { to: "/PersonalCenter/Message" },
                  nativeOn: {
                    click: function($event) {
                      _vm.stateSwitch(0)
                      _vm.message = false
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        position: "relative",
                        display: "inline-block",
                        "margin-right": "20px",
                        "vertical-align": "super",
                        cursor: "pointer"
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-message-solid",
                        staticStyle: { "font-size": "26px", color: "#409eff" }
                      }),
                      _c("div", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.message,
                            expression: "message"
                          }
                        ],
                        staticStyle: {
                          position: "absolute",
                          top: "0px",
                          "font-size": "12px",
                          color: "rgb(255, 255, 255)",
                          "background-color": "red",
                          width: "12px",
                          height: "12px",
                          "border-radius": "50%",
                          left: "12px",
                          "line-height": "20px",
                          "text-align": "center"
                        }
                      })
                    ]
                  )
                ]
              )
            : _vm._e(),
          _c(
            "el-dropdown",
            { staticClass: "avatar-container", attrs: { trigger: "click" } },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("img", {
                  staticClass: "user-avatar",
                  attrs: {
                    src: _vm.avatar
                      ? _vm.avatar
                      : _vm.photpsrc + "?imageView2/1/w/80/h/80"
                  }
                }),
                _c("i", { staticClass: "el-icon-caret-bottom" })
              ]),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "user-dropdown",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown"
                },
                [
                  _c(
                    "router-link",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.state == 1,
                          expression: "state == 1"
                        }
                      ],
                      attrs: { to: "/dashboard" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.stateSwitch(_vm.state)
                        }
                      }
                    },
                    [_c("el-dropdown-item", [_vm._v(" 主页 ")])],
                    1
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.state == 0,
                          expression: "state == 0"
                        }
                      ],
                      nativeOn: {
                        click: function($event) {
                          return _vm.turnStation(_vm.state)
                        }
                      }
                    },
                    [_vm._v(" 启程在线 ")]
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: { divided: "" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.logout($event)
                        }
                      }
                    },
                    [
                      _c("span", { staticStyle: { display: "block" } }, [
                        _vm._v("退出")
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }