<template>
  <div class="sourceChannel">
    <div class="head">
      <el-form class="info-form" auto-complete="on">
        <el-form-item label="渠道名称：">
          <el-input
            v-model="requestParams.attrCname"
            placeholder="搜索渠道名称"
          ></el-input>
        </el-form-item>
        <el-button
          @click="tableSearch"
          style="margin: 20px 10px 0 20px; float: left"
          type="primary"
          >搜索</el-button
        >
        <el-button
          @click="tableClear"
          style="margin: 20px 10px; float: left"
          type="primary"
          >清空</el-button
        >
        <el-button
          @click="
            operationChannel = true
            InfoTitle = '新增渠道'
            readonly = false
            InfoTableClear()
          "
          style="margin: 20px 10px; float: left"
          v-if="hasPermission.includes('新增')"
          type="primary"
          >新增
        </el-button>
      </el-form>
    </div>
    <div class="content">
      <el-table
        :header-cell-style="{ textAlign: 'center' }"
        :cell-style="{ textAlign: 'center' }"
        :data="tableData"
        max-height="630"
        v-loading="listLoading"
        @selection-change="handleSelectionChange"
        border
        style="width: 97%; margin: 0 30px"
      >
        <el-table-column prop="number" width="50" label="序号">
        </el-table-column>
        <template v-for="(item, index) in column">
          <el-table-column
            :key="index"
            :show-overflow-tooltip="true"
            :prop="item.prop"
            :label="item.label"
          >
          </el-table-column>
        </template>

        <el-table-column label="操作" width="520">
          <template slot-scope="scope">
            <el-button
              v-if="hasPermission.includes('排序')"
              size="mini"
              @click="UpOrDownMajor('up', scope.row)"
              >上升
            </el-button>
            <el-button
              v-if="hasPermission.includes('排序')"
              size="mini"
              @click="UpOrDownMajor('down', scope.row)"
              >下降
            </el-button>
            <el-button
              size="mini"
              v-if="hasPermission.includes('编辑')"
              @click="
                operationChannel = true
                InfoTitle = '编辑渠道'
                queryChannel(scope.row)
                readonly = false
              "
              >编辑
            </el-button>
            <el-button
              size="mini"
              v-if="hasPermission.includes('删除')"
              @click="handleDelete(scope.$index, scope.row)"
              >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <div style="text-align: center; margin: 10px">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          layout="prev, pager, next"
          :page-size="requestParams.pageSize"
          :current-page="requestParams.pageNum"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog
      :close-on-click-modal="false"
      title="删除渠道"
      :visible.sync="deleteChannel"
      width="25%"
    >
      <div>确定要删除该渠道吗？</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteChannel = false">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="removeChannel()"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      :title="InfoTitle"
      :visible.sync="operationChannel"
      :close-on-click-modal="false"
      @close="$refs.addForm.clearValidate()"
      width="30%"
    >
      <div>
        <el-form
          :model="addForm"
          :rules="addFormRules"
          ref="addForm"
          class="addForm"
          style="overflow: hidden; padding-left: 30px"
        >
          <el-form-item label="渠道名称" prop="attrCname">
            <el-input
              :readonly="readonly"
              v-model="addForm.attrCname"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="渠道分类" prop="channelType">
            <el-select
              :disabled="readonly"
              placeholder="请选择渠道分类"
              v-model="addForm.channelType"
            >
              <el-option label="运营" value="运营"></el-option>
              <el-option label="市场" value="市场"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="排序值" prop="channelSort">
            <el-input
              :readonly="readonly"
              v-model="addForm.channelSort"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            operationChannel = false
            readonly = false
          "
          >取 消</el-button
        >
        <el-button type="primary" :loading="loading" @click="saveChannel()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  queryChannelList,
  deleteChannel,
  insertChannel,
  editChannel,
  queryChannel,
  upDownObject
} from '@/api/SystemManager'
import { findRoleMenuButton } from '@/api/user'
const column = [
  {
    prop: 'attrCname',
    label: '渠道名称'
  },
  {
    prop: 'channelType',
    label: '渠道分类'
  },
  {
    prop: 'channelSort',
    label: '排序值'
  }
]
export default {
  name: 'sourceChannel',
  data() {
    return {
      column,
      hasPermission: [],
      listLoading: false,
      //表格
      tableData: [],
      //查询参数
      requestParams: {
        pageNum: 1,
        pageSize: 10,
        attrCname: ''
      },
      total: 0,
      multipleSelection: '',
      channelId: '',
      //编辑
      operationChannel: false,
      //删除
      deleteChannel: false,
      //弹出层
      InfoTitle: '',
      readonly: false,
      loading: false,
      //信息添加
      addForm: {
        attrCname: '',
        channelType: '',
        channelSort: ''
      },
      addFormRules: {
        attrCname: [
          {
            required: true,
            message: '请输入标签',
            trigger: ['blur', 'change']
          },
          {
            min: 2,
            max: 10,
            message: '长度不超过32个字符',
            trigger: ['blur', 'change']
          }
        ],
        channelType: [
          {
            required: true,
            message: '请输入渠道分类',
            trigger: ['blur', 'change']
          }
        ],
        channelSort: [
          {
            required: true,
            message: '请输入排序值',
            trigger: ['blur', 'change']
          }
        ]
      },
      pageList: []
    }
  },
  created() {
    this.doGetList()
    findRoleMenuButton({ name: 'sourceChannel' }).then((res) => {
      this.hasPermission = res.data
    })
  },
  methods: {
    //上升OR 下降
    UpOrDownMajor(type, row) {
      this.loading = true
      const parm = {
        id: row.id,
        type: type
      }
      upDownObject(parm).then((res) => {
        if (res.status == 200) {
          this.$message({
            message: res.message,
            type: 'success'
          })
          this.doGetList()
        } else {
          this.$message.error(res.message)
        }
        this.loading = false
      })
    },

    doGetList() {
      this.listLoading = true
      queryChannelList(this.requestParams).then((data) => {
        this.tableData = data.data.list
        this.requestParams.pageNum = data.data.current
        this.tableData.forEach((item, index) => {
          item.number =
            (this.requestParams.pageNum - 1) * data.data.pageSize + index + 1
        })
        this.total = data.data.total
        this.listLoading = false
      })
    },

    // 分页跳转
    handleCurrentChange(val) {
      this.requestParams.pageNum = val
      this.doGetList()
    },

    //表格搜素
    tableSearch() {
      this.requestParams.pageNum = 1
      this.doGetList()
    },

    //清空搜索条件并搜索
    tableClear() {
      this.requestParams = {
        pageNum: 1,
        pageSize: 10,
        labelName: ''
      }
      this.doGetList()
    },
    // 表格数据选中
    handleSelectionChange(val) {
      this.multipleSelection = val
    },

    // 添加|编辑渠道
    saveChannel() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          if (this.InfoTitle === '新增渠道') {
            insertChannel(this.addForm).then((res) => {
              if (res.status == 200) {
                this.$message({
                  message: res.message,
                  type: 'success'
                })
                this.operationChannel = false
                this.doGetList()
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            this.addForm.id = this.channelId
            this.channelId = ''
            editChannel(this.addForm).then((res) => {
              if (res.status == 200) {
                this.$message({
                  message: res.message,
                  type: 'success'
                })
                this.operationChannel = false
                this.doGetList()
              } else {
                this.$message.error(res.message)
              }
            })
          }
        } else {
          return false
        }
      })
    },

    //清空查看详情数据并搜索
    InfoTableClear() {
      this.addForm = {
        attrCname: '',
        channelSort: '',
        channelType: ''
      }
    },

    // 删除
    handleDelete(index, row) {
      this.deleteChannel = true
      this.channelId = row.id
    },
    removeChannel() {
      this.loading = true
      deleteChannel(this.channelId).then((res) => {
        if (res.status == 200) {
          this.$message({
            message: res.message,
            type: 'success'
          })
          this.channelId = ''
          this.deleteChannel = false
          this.doGetList()
        } else {
          this.$message.error(res.message)
        }
        this.loading = false
      })
    },

    //详情查询
    queryChannel(row) {
      this.InfoTableClear()
      this.channelId = row.id
      queryChannel(row.id).then((res) => {
        console.log('详情', res)
        for (let k in this.addForm) {
          this.addForm[k] = res.data[k]
        }
        console.log(this.addForm)
      })
    }
  }
}
</script>
<style lang="scss">
.sourceChannel {
  .el-form-item__content {
    width: 200px;
  }

  .el-form-item__label {
    width: 115px;
  }

  .addForm .jzhynx .el-form-item__label {
    width: 80px;
    margin-left: 35px;
    line-height: 20px;
  }
}
</style>
<style lang="scss" scoped>
.el-form-item {
  margin: 20px 0;
  display: flex;
  float: left;
}

.head {
  overflow: hidden;
  margin: 30px 0;
}

.content {
}

.addForm .el-form-item {
  margin-right: 50px;
}
</style>
<style scoped>
/deep/.exclusiveLinkForm .el-form-item {
  float: none;
}
/deep/.exclusiveLinkForm .el-form-item__content,
.exclusiveLinkForm .el-select {
  width: 240px;
}
</style>
