<template>
  <div style="display: inline-block; margin-left: 10px">
    <el-button
      @click="handleExport"
      type="primary"
      :loading="exportLoading"
      class="query-btn"
      ><img
        src="@/assets/export.png"
        alt=""
        class="query-btn-icon"
      />导出</el-button
    >
  </div>
</template>
<script>
import request from '@/utils/request'
import store from '@/store'
export default {
  props: [
    'requestParams', // 列表查询条件
    'action', // 列表接口url
    'columns', // 表头信息
    'filename', // 文件名称
    'customFields' // 自定义list字段
  ],
  data() {
    return {
      exportLoading: false
    }
  },
  methods: {
    getListRequest(data) {
      return request({
        url: this.action,
        method: 'post',
        data
      })
    },
    handleExport() {
      this.exportLoading = true
      let params = Object.assign({}, this.requestParams)
      // let params = Object.assign({}, this.requestParams, 'filename')
      params.pageNum = 1
      params.pageSize = 99999
      this.getListRequest(params).then((res) => {
        this.exportLoading = false
        let list
        if (res.data?.list) {
          list = res.data.list
        } else if (this.customFields) {
          list = res.data[this.customFields]
        }
        if (list && list.length == 0) {
          this.$message({
            type: 'warning',
            message: '暂无数据'
          })
          return
        } else if (res.data.records && res.data.records.length == 0) {
          this.$message({
            type: 'warning',
            message: '暂无数据'
          })
          return
        } else {
          import('@/vendor/Export2Excel').then((excel) => {
            let header = []
            let props = []
            this.columns.forEach((item) => {
              header.push(item.label)
              props.push(item.prop)
            })
            let items
            if (res.data?.list) {
              items = res.data.list
            } else if (this.customFields) {
              items = res.data[this.customFields]
            } else if (res.data.records) {
              items = res.data.records
            }
            const data = this.formatJson(props, items)
            excel.export_json_to_excel({
              header: header,
              data: data,
              filename: this.filename
            })
          })
        }
      })
    },
    formatJson(props, items) {
      console.log(items)
      return items.map((item) =>
        props.map((prop) => {
          switch (prop) {
            case 'date':
              return this.statsDates[0] + '至' + this.statsDates[1]
            case 'watchTime':
              return (item.watchTime / 1000 / 60).toFixed() + '分'
            case 'orderStatus':
              let arr = store.state.common.orderStatusList.filter((itemss) => {
                return item.orderStatus == itemss.value
              })
              if (arr && arr.length > 0) {
                return arr[0]['label']
              } else {
                return
              }
            case 'payMode':
              let arr2 = store.state.common.payModeList.filter((itemss) => {
                return item.payMode == itemss.key
              })
              if (arr2 && arr2.length > 0) {
                return arr2[0]['value']
              } else {
                return
              }
            default:
              return item[prop]
          }
        })
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.query-btn-icon {
  margin-right: 3px;
  display: block;
  width: 14px;
  height: 14px;
}

.query-btn {
  padding: 12px 15px;
  ::v-deep span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>