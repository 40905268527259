import Vue from 'vue'

import 'normalize.css/normalize.css' // A modern alternative to CSS resets
import 'animate.css';
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import EncryUtil from '@/utils/encrypt.js'
import '@/styles/index.scss' // global css
import App from './App'
import store from './store'
import router from './router'
import Clipboard from 'v-clipboard';
import '@/assets/iconfont/iconfont.css'
import ProviceData from '@/utils/country-level2-data.js'
import { baseURL } from '@/utils/request'
import * as publicMethods from '@/utils/utils'
import ExportExcel from '@/components/exportExcel.vue'
import TableComponents from '@/components/table-components.vue'
import ListQuery from '@/components/list-query/index.vue'
import FlexItem from '@/components/flex-item.vue'
// 引入echarts
import * as echarts from 'echarts';
Vue.prototype.ProviceData = ProviceData
Vue.prototype.$echarts = echarts
Vue.prototype.baseURL = baseURL
Vue.prototype.publicMethods = publicMethods
Vue.prototype.$eventBus = new Vue()
Vue.component('export-excel', ExportExcel)
Vue.component('table-components', TableComponents)
Vue.component('list-query', ListQuery)
Vue.component('flex-item', FlexItem)
import '@/icons' // icon
import '@/permission' // permission control
//引入 echarts 按需引入
//import * as echarts from 'echarts/core';
/*

/*if (process.env.NODE_ENV === 'production') {
}*/

// set ElementUI lang to EN
// Vue.use(ElementUI, { locale })
// 如果想要中文版 element-ui，按如下方式声明
Vue.use(ElementUI)
Vue.use(Clipboard)
Vue.prototype.EncryUtil = EncryUtil
Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
