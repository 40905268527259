<template>
  <div>
    <div style="display: flex">
      <el-date-picker
        v-model="monthsArr"
        type="monthrange"
        range-separator="至"
        start-placeholder="开始月份"
        end-placeholder="结束月份"
        value-format="yyyy-MM"
      >
      </el-date-picker>
      <Query :show-daterange="false" @search="handleSearch"></Query>
      <export-excel
        v-if="$store.state.common.hasPermission.includes('导出')"
        :requestParams="queryForm"
        action="/manage/wechat/data/monthContrastStatistics"
        :columns="columns"
        filename="数据统计-月度统计"
      ></export-excel>
    </div>
    <table-components
      :columns="columns"
      :total="total"
      :pageNum="pageNum"
      :pageSize="pageSize"
      :data="tableData"
      v-loading="loading"
      @get-list="getList"
    ></table-components>
  </div>
</template>
<script>
import { monthContrastStatistics } from '@/api/statistics'
import TableComponents from '@/components/table-components.vue'
let staticColumns = [
  {
    label: '所在部门',
    prop: 'deptName',
    width: '150',
    fixed: 'left'
  },
  {
    label: '课程顾问',
    prop: 'personName',
    width: '120',
    fixed: 'left'
  },
  {
    label: '账号',
    prop: 'wechatNum',
    width: '120',
    fixed: 'left'
  }
]
import Query from './components/query.vue'
export default {
  components: {
    Query,
    TableComponents
  },
  data() {
    return {
      staticColumns,
      columns: [],
      queryForm: {},
      pageNum: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      loading: false,
      monthsArr: []
    }
  },
  created() {
    this.$store.dispatch('findRoleMenuButtonFn', 'StatisticsMonthly')
    this.monthsArr = [
      this.publicMethods.recentTime(-60, 'yyyy-MM'),
      this.publicMethods.recentTime(0, 'yyyy-MM')
    ]
  },
  methods: {
    // 获取两个月份直接的所有月 需求要求查询起始月-结束月 包括结束月,后端接口查询的是不包括结束月，所以endTime传参需要向后推下一个月
    getMonthsBetween(startMonth, endMonth) {
      const startYear = startMonth.getFullYear()
      let currentMonth = new Date(startYear, startMonth.getMonth() + 1)
      let months = []
      while (this.formatterDate(currentMonth) <= this.formatterDate(endMonth)) {
        months.push(new Date(currentMonth).toISOString().substring(0, 7))
        currentMonth.setMonth(currentMonth.getMonth() + 1)
      }
      return months
    },
    // 时间转为标准格式
    formatterDate(date) {
      return new Date(date).toISOString().substring(0, 7)
    },
    // 获取当前月的下一个月份
    getNextMonth(date) {
      let year = parseInt(date.split('-')[0])
      let month = parseInt(date.split('-')[1])
      if (month == 12) {
        month = 1
        year = parseInt(year) + 1
      } else {
        month += 1
      }
      if (month < 10) {
        month = year + '-' + '0' + month
      }
      return month
    },
    handleSearch(queryForm) {
      this.queryForm = queryForm
      this.queryForm.modeType = 'M'
      this.queryForm.pageNum = this.pageNum
      this.queryForm.pageSize = this.pageSize
      if (this.publicMethods.isEmpty(this.monthsArr)) {
        this.queryForm.startTime = ''
        this.queryForm.endTime = ''
      } else {
        this.queryForm.startTime = this.monthsArr[0]
        this.queryForm.endTime = this.getNextMonth(this.monthsArr[1])
      }
      console.log('参数', this.queryForm)
      // 获取月份之间的表头
      this.getColumns()
      this.getList(1)
    },
    getColumns() {
      let months = this.getMonthsBetween(
        new Date(this.monthsArr[0]),
        new Date(this.monthsArr[1])
      )

      this.columns = []
      let dynamicsColumns = []
      months.forEach((item, index) => {
        if (index == 0) {
          dynamicsColumns = [
            {
              label: item + '好友总数',
              prop: 'totalFriendNum' + (index + 1),
              width: 140
            },
            {
              label: item + '新增好友数',
              prop: 'addFriendNum' + (index + 1),
              width: 150
            },
            {
              label: item + '删除好友数',
              prop: 'delFriendNum' + (index + 1),
              width: 150
            },
            {
              label: item + '净增好友数',
              prop: 'netGrowthFriendNum' + (index + 1),
              width: 150
            }
          ]
        } else {
          dynamicsColumns.push(
            {
              label: item + '好友总数',
              prop: 'totalFriendNum' + (index + 1),
              width: 150
            },
            {
              label: item + '较上月增加(个)',
              prop: 'totalLastNum' + (index + 1),
              width: 170
            },
            {
              label: item + '较上月增加(%)',
              prop: 'totalLastPercent' + (index + 1),
              width: 170
            },
            {
              label: item + '新增',
              prop: 'addFriendNum' + (index + 1),
              width: 150
            },
            {
              label: item + '较上月增加(个)',
              prop: 'addLastNum' + (index + 1),
              width: 170
            },
            {
              label: item + '较上月增加(%)',
              prop: 'addLastPercent' + (index + 1),
              width: 170
            },
            {
              label: item + '删除',
              prop: 'delFriendNum' + (index + 1),
              width: 150
            },
            {
              label: item + '较上月增加(个)',
              prop: 'delLastNum' + (index + 1),
              width: 170
            },
            {
              label: item + '较上月增加(%)',
              prop: 'delLastPercent' + (index + 1),
              width: 170
            },
            {
              label: item + '净增',
              prop: 'netGrowthFriendNum' + (index + 1),
              width: 150
            },
            {
              label: item + '较上月增加(个)',
              prop: 'netGrowthLastNum' + (index + 1),
              width: 170
            },
            {
              label: item + '较上月增加(%)',
              prop: 'netGrowthLastPercent' + (index + 1),
              width: 170
            }
          )
        }
      })
      this.columns = this.staticColumns.concat(dynamicsColumns)
    },
    getList(pageNum) {
      if (pageNum) {
        this.queryForm.pageNum = pageNum
      }
      this.loading = true
      monthContrastStatistics(this.queryForm).then((res) => {
        this.loading = false
        if (res.status == 200) {
          this.tableData = res.data.list
          this.total = res.data.total
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
</style>