<template>
  <div>
    <div>
      <!-- 新增好友数 -->

      <div style="display: flex">
        <query ref="addFriend" @search="getStatisticsAddFriend"></query>
        <export-excel
          v-if="$store.state.common.hasPermission.includes('导出')"
          :requestParams="addFriendQuery"
          action="/manage/wechat/data/statisticsAddFriend"
          :columns="addColumns"
          filename="数据统计-新增好友数"
        ></export-excel>
      </div>
      <div v-loading="addFriendLoading">
        <ChartLine ref="addFriend_chart" id="addFriend_chart" />
      </div>

      <div class="gap"></div>
      <!-- 删除好友数 -->

      <div style="display: flex">
        <query @search="getStatisticsDelFriend"></query>
        <export-excel
          v-if="$store.state.common.hasPermission.includes('导出')"
          :requestParams="delFriendQuery"
          action="/manage/wechat/data/statisticsDelFriend"
          :columns="delColumns"
          filename="数据统计-删除好友数"
        ></export-excel>
      </div>
      <ChartLine ref="delFriend_chart" id="delFriend_chart" />

      <div class="gap"></div>
      <!-- 好友总数 -->

      <div style="display: flex">
        <query @search="getStatisticsTotalFriend"></query>
        <export-excel
          v-if="$store.state.common.hasPermission.includes('导出')"
          :requestParams="totalFriendQuery"
          action="/manage/wechat/data/statisticsFriendTotal"
          :columns="totalColumns"
          filename="数据统计-好友总数"
        ></export-excel>
      </div>
      <ChartLine ref="totalFriend_chart" id="totalFriend_chart" />
    </div>
  </div>
</template>
<script>
import Query from './components/query'
import ChartLine from '@/components/statistics/chartLint'
import {
  statisticsAddFriend,
  statisticsDelFriend,
  statisticsFriendTotal
} from '@/api/statistics'
const baseColumns = [
  {
    prop: 'personName',
    label: '使用人'
  },
  {
    prop: 'wechatNum',
    label: '账号'
  }
]
export default {
  components: {
    Query,
    ChartLine
  },
  data() {
    return {
      baseColumns,
      addFriendLoading: false,
      addFriendQuery: {},
      addColumns: [],
      delFriendLoading: false,
      delFriendQuery: {},
      delColumns: [],
      totalFriendLoading: false,
      totalFriendQuery: {},
      totalColumns: []
    }
  },
  created() {
    this.$store.dispatch('findRoleMenuButtonFn', 'StatisticsSingleDay')
  },
  mounted() {},
  methods: {
    // 查询新增好友数
    getStatisticsAddFriend(params) {
      this.addFriendQuery = params
      this.addColumns = []
      this.addFriendLoading = true
      statisticsAddFriend(params).then((res) => {
        this.addFriendLoading = false
        if (res.status == 200) {
          let data = res.data
          this.$refs.addFriend_chart.initChart(
            '新增好友数',
            data.name,
            data.time,
            data.values
          )
          this.addColumns = this.getExportColumns(data.time)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 获取导出的表头列
    getExportColumns(data) {
      let columns = Object.assign([], this.baseColumns)
      if (!this.publicMethods.isEmpty(data)) {
        data.forEach((item) => {
          columns.push({
            prop: item,
            label: item
          })
        })
      }
      return columns
    },
    // 查询删除好友数
    getStatisticsDelFriend(params) {
      this.delFriendLoading = true
      this.delFriendQuery = params
      this.delColumns = []
      statisticsDelFriend(params).then((res) => {
        this.delFriendLoading = false
        if (res.status == 200) {
          let data = res.data
          this.$refs.delFriend_chart.initChart(
            '删除好友数',
            data.name,
            data.time,
            data.values
          )
          this.delColumns = this.getExportColumns(data.time)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 查询删除好友数
    getStatisticsTotalFriend(params) {
      this.totalFriendLoading = true
      this.totalFriendQuery = params
      this.totalColumns = []
      statisticsFriendTotal(params).then((res) => {
        this.totalFriendLoading = false
        if (res.status == 200) {
          let data = res.data
          this.$refs.totalFriend_chart.initChart(
            '好友总数',
            data.name,
            data.time,
            data.values
          )
          this.totalColumns = this.getExportColumns(data.time)
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.gap {
  margin: 80px 0;
  border-bottom: 1px solid #cdcdcd;
}
</style>