var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [
        _c(
          "div",
          { staticStyle: { display: "flex" } },
          [
            _c("query", {
              ref: "addFriend",
              on: { search: _vm.getStatisticsAddFriend }
            }),
            _vm.$store.state.common.hasPermission.includes("导出")
              ? _c("export-excel", {
                  attrs: {
                    requestParams: _vm.addFriendQuery,
                    action: "/manage/wechat/data/statisticsAddFriend",
                    columns: _vm.addColumns,
                    filename: "数据统计-新增好友数"
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.addFriendLoading,
                expression: "addFriendLoading"
              }
            ]
          },
          [
            _c("ChartLine", {
              ref: "addFriend_chart",
              attrs: { id: "addFriend_chart" }
            })
          ],
          1
        ),
        _c("div", { staticClass: "gap" }),
        _c(
          "div",
          { staticStyle: { display: "flex" } },
          [
            _c("query", { on: { search: _vm.getStatisticsDelFriend } }),
            _vm.$store.state.common.hasPermission.includes("导出")
              ? _c("export-excel", {
                  attrs: {
                    requestParams: _vm.delFriendQuery,
                    action: "/manage/wechat/data/statisticsDelFriend",
                    columns: _vm.delColumns,
                    filename: "数据统计-删除好友数"
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _c("ChartLine", {
          ref: "delFriend_chart",
          attrs: { id: "delFriend_chart" }
        }),
        _c("div", { staticClass: "gap" }),
        _c(
          "div",
          { staticStyle: { display: "flex" } },
          [
            _c("query", { on: { search: _vm.getStatisticsTotalFriend } }),
            _vm.$store.state.common.hasPermission.includes("导出")
              ? _c("export-excel", {
                  attrs: {
                    requestParams: _vm.totalFriendQuery,
                    action: "/manage/wechat/data/statisticsFriendTotal",
                    columns: _vm.totalColumns,
                    filename: "数据统计-好友总数"
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _c("ChartLine", {
          ref: "totalFriend_chart",
          attrs: { id: "totalFriend_chart" }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }