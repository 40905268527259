<template>
  <div class="recover">
    <div class="head">
      <el-form :model="queryForm">
        <el-form-item label="恢复微信号：">
          <el-input
            v-model="queryForm.wechatNum"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="恢复时间：" style="width: 500px">
          <el-date-picker
            v-model="createTime"
            type="datetimerange"
            range-separator="至"
            value-format="yyyy-MM-dd HH:mm:ss"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-button @click="tableSearch" type="primary">查询</el-button>
        <export-excel
          v-if="$store.state.common.hasPermission.includes('导出')"
          :requestParams="queryForm"
          action="/manage/assets/track/recoveryPage"
          :columns="columns"
          filename="恢复记录"
        ></export-excel>
      </el-form>
    </div>
    <div class="content">
      <el-table
        :header-cell-style="{ textAlign: 'center' }"
        :data="tableData"
        border
        :cell-style="{ textAlign: 'center' }"
        style="width: 100%"
      >
        <el-table-column
          v-for="item in columns"
          :prop="item.prop"
          :label="item.label"
          :formatter="item.formatter"
        ></el-table-column>
      </el-table>
      <!--分页-->
      <div style="text-align: center; margin: 10px">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          layout="prev, pager, next"
          :page-size="queryForm.pageSize"
          :current-page="queryForm.pageNum"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { recoveryPage } from '@/api/operateRecords'

let columns = [
  {
    label: '恢复微信号',
    prop: 'wechatNum'
  },
  {
    label: '恢复时间',
    prop: 'createdTime'
  },
  {
    label: '操作人',
    prop: 'createdByName'
  }
]
export default {
  data() {
    return {
      columns: columns,
      createTime: [],
      dialogForm: {
        wechatNum: ''
      },
      dialogVisible: false,
      queryForm: {
        pageNum: 1,
        pageSize: 10,
        recoveryStart: '',
        recoveryEnd: '',
        wechatNum: ''
      },
      total: 0,
      tableData: [],
      loading: false
    }
  },
  created() {
    this.$store.dispatch('findRoleMenuButtonFn', 'operateRecordsRecover')
    const end = new Date().Format('yyyy-MM-dd 23:59:59')
    const start = new Date()
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
    this.createTime = [start.Format('yyyy-MM-dd 00:00:00'), end]
    this.queryForm.recoveryStart = start.Format('yyyy-MM-dd 00:00:00')
    this.queryForm.recoveryEnd = end
    this.doGetList()
  },
  methods: {
    // 分页跳转
    handleCurrentChange(val) {
      this.queryForm.pageNum = val
      this.doGetList()
    },
    tableSearch() {
      if (this.createTime) {
        this.queryForm.recoveryStart = this.createTime[0]
        this.queryForm.recoveryEnd = this.createTime[1]
      } else {
        this.queryForm.recoveryStart = ''
        this.queryForm.recoveryEnd = ''
      }
      this.queryForm.pageNum = 1
      this.doGetList()
    },
    doGetList() {
      this.loading = true
      recoveryPage(this.queryForm).then((data) => {
        this.tableData = data.data.list
        this.queryForm.pageNum = data.data.current
        this.total = data.data.total
        this.loading = false
      })
    }
  }
}
</script>
<style lang="scss">
.recover {
  .el-form-item {
    margin-right: 20px;
  }

  .el-form-item,
  .el-form-item__label,
  .el-form-item__content {
    display: inline-block;
  }

  .el-form-item__content {
    width: 200px;
  }
}
</style>
<style lang="scss" scoped>
::v-deep .table-input .el-input__inner {
  text-align: center;
}

::v-deep .abnormal-dialog {
  .el-select {
    width: 378px;
  }

  .el-input__inner {
    width: 378px;
  }

  .el-textarea {
    width: 380px;
  }
}
</style>
