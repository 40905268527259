import { Message } from 'element-ui';
import Cookies from 'js-cookie'
import store from '@/store'

// 将数组类型字段转为逗号隔开的字符串类型
export function changeArrtoStr(e) {
  let str = ''
  if (!isEmpty(e)) {
    str = e.join(',')
  } else {
    str = ''
  }
  return str
}
// 将逗号隔开的字符串转换为Number数组类型
export function changeStrsToNumarr(e) {
  let arr = []
  if (!isEmpty(e)) {
    arr = e.split(',')
    arr = arr.map(item => {
      return Number(item)
    })
  } else {
    arr = ''
  }
  return arr
}
// 非空判断  空-true  非空-false
export function isEmpty(data) {
  if (!data) {
    return true
  }
  if (JSON.stringify(data) === '{}') {
    return true
  }
  if (JSON.stringify(data) === '[]') {
    return true
  }
  return false
}
// 设置默认时间范围
export function setDefaultDateTime(beforeDays) {
  /*
  * beforeDays: 距今天之前beforeDays天数
  */
  Date.prototype.Format = function (fmt) {
    // author: meizz
    var o = {
      'M+': this.getMonth() + 1, // 月份
      'd+': this.getDate(), // 日
      'h+': this.getHours(), // 小时
      'm+': this.getMinutes(), // 分
      's+': this.getSeconds(), // 秒
      'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
      S: this.getMilliseconds() // 毫秒
    }
    if (/(y+)/.test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        (this.getFullYear() + '').substr(4 - RegExp.$1.length)
      )
    for (var k in o)
      if (new RegExp('(' + k + ')').test(fmt))
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length == 1
            ? o[k]
            : ('00' + o[k]).substr(('' + o[k]).length)
        )
    return fmt
  }
  const end = new Date().Format('yyyy-MM-dd 23:59:59')
  const start = new Date()
  start.setTime(start.getTime() - 3600 * 1000 * 24 * beforeDays)
  return [start.Format('yyyy-MM-dd 00:00:00'), end]
}
// 金额-任意正整数 （小数点不超过2位）
export function verificationAmount(val) {
  let isAmount = /^(([0-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;
  return isAmount.test(val)
}
// 获取当前时间的时间分
export function getNowTime() {
  var date = new Date();
  //年 getFullYear()：四位数字返回年份
  var year = date.getFullYear(); //getFullYear()代替getYear()
  //月 getMonth()：0 ~ 11
  var month = date.getMonth() + 1;
  //日 getDate()：(1 ~ 31)
  var day = date.getDate();
  //时 getHours()：(0 ~ 23)
  var hour = date.getHours();
  //分 getMinutes()： (0 ~ 59)
  var minute = date.getMinutes();
  //秒 getSeconds()：(0 ~ 59)
  var second = date.getSeconds();
  return addZeor(hour) + ':' + addZeor(minute) + ':' + addZeor(second)
}
// 获取当前 年月日 时分秒
Date.prototype.format = function (format) {
  var o = {
    "M+": this.getMonth() + 1, //month
    "d+": this.getDate(), //day
    "h+": this.getHours(), //hour
    "m+": this.getMinutes(), //minute
    "s+": this.getSeconds(), //second
    "q+": Math.floor((this.getMonth() + 3) / 3), //quarter
    "S": this.getMilliseconds() //millisecond
  }
  if (/(y+)/.test(format)) format = format.replace(RegExp.$1,
    (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(format))
      format = format.replace(RegExp.$1,
        RegExp.$1.length == 1 ? o[k] :
          ("00" + o[k]).substr(("" + o[k]).length));
  return format;
}
// 小于10的拼接上0字符串
export function addZeor(s) {
  return s < 10 ? ('0' + s) : s
}
// s转时分秒
export function formatSeconds(value) {
  var theTime = parseInt(value);// 秒
  var theTime1 = 0;// 分
  var theTime2 = 0;// 小时
  if (theTime > 60) {
    theTime1 = parseInt(theTime / 60);
    theTime = parseInt(theTime % 60);
    if (theTime1 > 60) {
      theTime2 = parseInt(theTime1 / 60);
      theTime1 = parseInt(theTime1 % 60);
    }
  }
  var result = "" + parseInt(theTime) + "秒";
  if (theTime1 > 0) {
    result = "" + parseInt(theTime1) + "分" + result;
  }
  if (theTime2 > 0) {
    result = "" + parseInt(theTime2) + "小时" + result;
  }
  return result;
}
// KB，MB...单位转换
export function formatterSize(val) {
  if (val == 0) return "0 B"
  var k = 1024;
  var sizes = ['B', 'KB', 'MB', 'GB', 'PB', 'TB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(val) / Math.log(k));
  return (val / Math.pow(k, i)).toPrecision(3) + "" + sizes[i]
}

// 阿拉伯数字转数字大写
export function Arabia_To_SimplifiedChinese(Num) {
  //字符处理完毕后开始转换，采用前后两部分分别转换
  let part = String(Num).split(".");
  let newchar = "";
  //小数点前进行转化
  for (let i = part[0].length - 1; i >= 0; i--) {
    if (part[0].length > 10) {
      //alert("位数过大，无法计算");
      return "";
    }//若数量超过拾亿单位，提示
    let tmpnewchar = ""
    let perchar = part[0].charAt(i);
    switch (perchar) {
      case "0": tmpnewchar = "零" + tmpnewchar; break;
      case "1": tmpnewchar = "一" + tmpnewchar; break;
      case "2": tmpnewchar = "二" + tmpnewchar; break;
      case "3": tmpnewchar = "三" + tmpnewchar; break;
      case "4": tmpnewchar = "四" + tmpnewchar; break;
      case "5": tmpnewchar = "五" + tmpnewchar; break;
      case "6": tmpnewchar = "六" + tmpnewchar; break;
      case "7": tmpnewchar = "七" + tmpnewchar; break;
      case "8": tmpnewchar = "八" + tmpnewchar; break;
      case "9": tmpnewchar = "九" + tmpnewchar; break;
    }
    switch (part[0].length - i - 1) {
      case 0: tmpnewchar = tmpnewchar; break;
      case 1: if (perchar != 0) tmpnewchar = tmpnewchar + "十"; break;
      case 2: if (perchar != 0) tmpnewchar = tmpnewchar + "百"; break;
      case 3: if (perchar != 0) tmpnewchar = tmpnewchar + "千"; break;
      case 4: tmpnewchar = tmpnewchar + "万"; break;
      case 5: if (perchar != 0) tmpnewchar = tmpnewchar + "十"; break;
      case 6: if (perchar != 0) tmpnewchar = tmpnewchar + "百"; break;
      case 7: if (perchar != 0) tmpnewchar = tmpnewchar + "千"; break;
      case 8: tmpnewchar = tmpnewchar + "亿"; break;
      case 9: tmpnewchar = tmpnewchar + "十"; break;
    }
    newchar = tmpnewchar + newchar;
  }
  //替换所有无用汉字，直到没有此类无用的数字为止
  while (newchar.search("零零") != -1 || newchar.search("零亿") != -1 || newchar.search("亿万") != -1 || newchar.search("零万") != -1) {
    newchar = newchar.replace("零亿", "亿");
    newchar = newchar.replace("亿万", "亿");
    newchar = newchar.replace("零万", "万");
    newchar = newchar.replace("零零", "零");
  }
  //替换以“一十”开头的，为“十”
  if (newchar.indexOf("一十") == 0) {
    newchar = newchar.substr(1);
  }
  //替换以“零”结尾的，为“”
  if (newchar.lastIndexOf("零") == newchar.length - 1) {
    newchar = newchar.substr(0, newchar.length - 1);
  }
  return newchar;
}
/*
 * 时间格式转化，获取前后n天的具体时间 
 * @param day 前后n天 0为当天，正值表示未来n天，负值表示过去n天
 * @param fmt 时间转化的格式
 * @param time 传入时间，默认为当前时间
 * eg: recentTime(9, "yyyy-MM-dd", getCurrentDate())
 */
export function recentTime(day, fmt, time) {
  //获取当前时间的毫秒值
  let now = (time ? new Date(time) : new Date()).getTime();
  // 获取前后n天时间
  let recent = new Date(now + day * 24 * 60 * 60 * 1000)

  // key：正则匹配表达式，value：对应的时间、日期
  let fmtObj = {
    'M+': recent.getMonth() + 1, 		//月份
    'd+': recent.getDate(),  		//日
    'h+': recent.getHours(), 		//时
    'm+': recent.getMinutes(),		//分
    's+': recent.getSeconds(),		//秒
  }
  // 获取匹配年份替换
  if (/(y+)/.test(fmt)) {
    //RegExp.$1 匹配结果，替换成对应的长度。如：yyyy就替换成整个年份2021，yy就替换成后两位21，以此类推
    fmt = fmt.replace(RegExp.$1, (recent.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  for (let key in fmtObj) {
    if (new RegExp(`(${key})`).test(fmt)) {
      //日期，时、分、秒替换，判断fmt是否需要补0，如：yyyy-M-d h:m:s 不补0,yyyy-MM-dd hh:mm:ss 则自动补0
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? fmtObj[key] : ('00' + fmtObj[key]).substr(('' + fmtObj[key]).length))
    }
  }
  return fmt
}